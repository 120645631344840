import React, {useEffect} from 'react';
import {useAuth} from '@frontegg/react';
import {useNavigate, useLocation} from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import './App.css';


function App() {
    const {user, isAuthenticated, isLoading} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoading && !isAuthenticated && location.pathname !== "/account/login") {
            navigate("/account/login");
        }
    }, [isAuthenticated, isLoading, location, navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex h-screen bg-gray-100">
            {user && <Sidebar user={user}/>}
            <div className="flex-1 overflow-auto">
                <Dashboard/>
            </div>
        </div>
    );
}

export default App;
import * as React from "react";
import NavLink from "./NavLink";

function NavigationSection({ title, links }) {
  return (
    <section className="flex flex-col py-5 pr-0 text-sm text-white">
      <h2 className="pl-8 mb-2.5 text-neutral-400">
        <strong>{title}</strong>
      </h2>
      <div className="flex flex-col">
        {links.map((link, index) => (
          <NavLink
            key={index}
            text={link.text}
            icon={link.icon}
            href={link.href}
            onClick={link.onClick}
            isActive={link.isActive}
            isExternal={link.isExternal}
          />
        ))}
      </div>
    </section>
  );
}

export default NavigationSection;
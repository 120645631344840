import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons';

function NavLink({ text, icon, href, isActive, isExternal, onClick }) {
  return (
    <a
      href={href}
      className={`
        py-3 pl-8 
        no-underline 
        text-white 
        font-bold
        ${!isActive ? 'text-opacity-70' : ''} 
        transition-colors 
        duration-200 
        hover:bg-white 
        hover:bg-opacity-20
        flex
        items-center 
        gap-3
      `}
      tabIndex="0"
      role="link"
      aria-current={isActive ? 'page' : undefined}
      onClick={onClick ? onClick : undefined}
      target={isExternal ? "_blank" : undefined}
      rel={isExternal ? "noopener noreferrer" : undefined}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={`w-4 h-4 ${!isActive ? 'opacity-70' : ''}`}
        />
      )}
      {text}
      {isExternal && (
        <FontAwesomeIcon
          icon={faArrowTurnUp}
          className="w-4 h-4 ml-2 opacity-70"
          aria-label="External link"
        />
      )}
    </a>
  );
}

export default NavLink;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FronteggProvider } from '@frontegg/react';

const contextOptions = {
  baseUrl: 'https://auth.insightmind.ai',
  clientId: 'b7466d71-f266-4f1c-ae4e-3eadd5aebeb0',
  appId: 'e68921fc-7283-41bb-a6a0-f9db24e8427f'
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <FronteggProvider contextOptions={contextOptions}>
            <App/>
        </FronteggProvider>
    </React.StrictMode>
);

reportWebVitals();
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminPortal } from '@frontegg/react';
import NavigationSection from './NavigationSection';
import {
    faChartSimple,
    faPeopleGroup,
    faWebAwesome,
    faFolderClosed,
    faCirclePlus,
    faElevator,
    faBookBookmark,
    faLaptopFile,
    faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const handleManageUserClick = (e) => {
    e.preventDefault();
    AdminPortal.show();
};

const Sidebar = ({user}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const handleLogout = () => {
        setShowLogoutDialog(true);
    };

    const confirmLogout = () => {
        navigate('/account/logout');
    };

    const navigationData = {
        dashboards: {
            title: 'DASHBOARDS',
            links: [
                {
                    text: 'LTV Forecast',
                    href: '/ltv',
                    icon: faChartSimple,
                    isActive: location.pathname === '/ltv',
                },
                {
                    text: 'Churn Forecast',
                    href: '/churn',
                    icon: faPeopleGroup,
                    isActive: location.pathname === '/churn',
                },
                {
                    text: 'VIP Forecast',
                    href: '/vip',
                    icon: faWebAwesome,
                    isActive: location.pathname === '/vip',
                }
            ]
        },
        reports: {
            title: 'REPORTS',
            links: [
                {
                    text: 'Show all',
                    href: 'https://data.insightmind.ai/collection/16-reports',
                    icon: faFolderClosed,
                    isActive: false,
                    isExternal: true
                },
                {
                    text: 'Create new',
                    href: 'https://data.insightmind.ai/collection/16-reports',
                    icon: faCirclePlus,
                    isActive: false,
                    isExternal: true,
                }
            ]
        },
        manage: {
            title: 'MANAGE',
            links: [
                {
                    text: 'Settings',
                    href: '/settings',
                    icon: faElevator,
                    isActive: location.pathname === '/settings',
                    onClick: handleManageUserClick,
                }
            ]
        }
    };

    return (
        <>
            <aside className="flex flex-col h-screen bg-center bg-no-repeat bg-cover bg-[url(https://cdn.builder.io/api/v1/image/assets/TEMP/ca0f23c9011eb4ad254218d29e7cb2008c94a58862310c5e2ae97a65fcdd3cf8?placeholderIfAbsent=true&apiKey=4c91241011884c1894a3c60084e78409)] bg-indigo-950 max-w-[200px] shadow-[rgba(0,0,0,0.1)_2px_0px_5px]">
                <header className="flex flex-col px-8 py-5">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets%2F4c91241011884c1894a3c60084e78409%2F98245929fccf4e4985d35f76ddf7d0d6"
                        alt="Company Logo"
                        className="object-contain w-full aspect-[3_/_1] max-w-[200px] min-h-[30px]"
                    />
                </header>

                <main className="flex flex-col grow">
                    <NavigationSection {...navigationData.dashboards} />
                    <NavigationSection {...navigationData.reports} />
                    <NavigationSection {...navigationData.manage} />
                </main>

                <footer
                    className="px-8 py-5 text-sm border-t border-gray-500 border-t-opacity-10 text-white text-opacity-70 flex items-center justify-between cursor-pointer group"
                    onClick={handleLogout}
                >
                    <span className="font-lato w-[112px] truncate">{user.email}</span>
                    <FontAwesomeIcon
                        icon={faRightFromBracket}
                        className="text-white text-opacity-70 group-hover:text-red-500 transition-colors ml-8"
                    />
                </footer>
            </aside>

            {/* Custom Logout Dialog */}
            {showLogoutDialog && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            setShowLogoutDialog(false);
                        }
                    }}
                >
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-4 w-full">
                        <h2 className="text-xl font-semibold mb-4">
                            Confirm Logout
                        </h2>
                        <p className="text-gray-600 mb-6">
                            Are you sure you want to logout?
                        </p>
                        <div className="flex justify-end gap-4">
                            <button
                                className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200 transition-colors"
                                onClick={() => setShowLogoutDialog(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600 transition-colors"
                                onClick={confirmLogout}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sidebar;